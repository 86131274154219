import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

import getConfig from "next/config"

const {
  publicRuntimeConfig: { API_URL },
} = getConfig()

export const bookmarksSlice = createSlice({
  name: "bookmarks",
  initialState: {
    sidebar_open: false,
    bookmarks: [],
    bookmarkedJobs: [],
  },
  reducers: {
    setBookmarks: (state, action) => {
      state.bookmarks = action.payload
    },
    setSidebarOpen: (state, action) => {
      state.sidebar_open = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBookmarkedJobs.fulfilled, (state, action) => {
      state.bookmarkedJobs = action?.payload?.data ?? []
      state.bookmarks = state.bookmarks.filter((bookmark) =>
        state.bookmarkedJobs.find(
          (item) => `${item.externalId}` === `${bookmark}`,
        ),
      )
    })
  },
})

export const getBookmarkedJobs = createAsyncThunk(
  "bookmarks/getBookmarkedJobs",
  async (payload, thunkAPI) => {
    const { bookmarks } = thunkAPI.getState()

    if (
      !bookmarks?.bookmarks ||
      (Array.isArray(bookmarks.bookmarks) && bookmarks.bookmarks.length === 0)
    ) {
      return
    }

    return await axios.post(`${API_URL}/api/jobs/bookmarks`, {
      bookmarks: bookmarks.bookmarks,
    })
  },
)

export const { setBookmarks, setSidebarOpen } = bookmarksSlice.actions

export const bookmarksReducer = bookmarksSlice.reducer
