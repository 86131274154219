
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "../styles/globals.scss";
import "intl-pluralrules";
import "intersection-observer";
import ReduxProvider from "../store/ReduxProvider";
import "lib/fontawesome.setup";
import { useEffect } from "react";
import { store } from "../store";
import { setSource } from "../store/slices/trackingSlice";
function MyApp({ Component, pageProps }) {
    useEffect(() => {
        const cookieName = "tr_s";
        const cookie = JSON.parse(decodeURIComponent(document?.cookie
            ?.split(";")
            ?.map((c) => c.trim())
            ?.find((c) => c.startsWith("tr_s="))
            ?.replace(`${cookieName}=`, "") ?? null));
        if (cookie && cookie?.source && cookie?.target) {
            store.dispatch(setSource(cookie));
        }
    }, []);
    return (<ReduxProvider>
      <noscript>
        <iframe src={"https://www.googletagmanager.com/ns.html?id=GTM-5N6NSX2L"} height={"0"} width={"0"} style={{ display: "none", visibility: "hidden" }}></iframe>
      </noscript>
      <Component {...pageProps}/>
    </ReduxProvider>);
}
const __Next_Translate__Page__192f7934eb7__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__192f7934eb7__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  