import {
  createAsyncThunk,
  createSelector,
  createSlice,
  current,
} from "@reduxjs/toolkit"
import axios from "axios"

import getConfig from "next/config"

const {
  publicRuntimeConfig: { API_URL },
} = getConfig()

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    isInitialPageload: true,
    term: "",
    location: {
      city: null,
      lat: null,
      lng: null,
      range: 30,
      display: null,
      bounds: null,
    },
    ranges: {
      10: "10 km",
      20: "20 km",
      30: "30 km",
      50: "50 km",
      75: "75 km",
      100: "100 km",
      0: "unbegrenzt",
    },
    isLoading: false,
    defaultRange: 30,
    results: [],
    meta: {},
    resultsMap: [], // only used for map view
    metaMap: {}, // only used for map view
    filters: [],
    activeFilters: [],
    mapSelectedJob: null,
    sort: "relevance",
  },
  reducers: {
    setTerm: (state, action) => {
      state.term = action.payload
    },
    setLocation: (state, action) => {
      state.location = action.payload
    },
    setResults: (state, action) => {
      state.results = action.payload
    },
    setMeta: (state, action) => {
      state.meta = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setActiveFilters: (state, action) => {
      state.activeFilters = Object.fromEntries(
        Object.entries(action.payload).filter(([key, value]) => {
          const currentFilters = current(state).filters

          const filter = currentFilters.find((f) => f.id === key)
          if (!filter) return false

          const validValues = filter.multiple
            ? value
                .filter((v) => filter.options.find((o) => o.id === v))
                .map((v) => v)
            : [filter.options.find((o) => o.id === value)].filter(
                (v) => v,
              )?.[0] || null

          return [key, validValues]
        }),
      )
    },
    setSort: (state, action) => {
      state.sort = action.payload
    },
    setMapSelectedJob: (state, action) => {
      state.mapSelectedJob = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchJobs.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(searchJobs.fulfilled, (state, action) => {
      const { map = false } = action?.meta?.arg

      if (map) {
        state.resultsMap = action.payload?.data?.results
        state.metaMap = action.payload?.data?.meta
      } else {
        state.results = action.payload?.data?.results
        state.meta = action.payload?.data?.meta
      }

      state.filters =
        action.payload?.data?.filters?.sort((a, b) => a.order - b.order) || []

      state.isLoading = false

      state.isInitialPageload = false
    })
  },
})

// THUNKS
export const searchJobs = createAsyncThunk(
  "search/searchJobs",
  async (payload, thunkAPI) => {
    const { search } = thunkAPI.getState()
    const { currentPage = 1, map = false, cancelToken } = payload

    let searchParams = {
      term: search.term,
      filters: search.activeFilters,
      log: true,
    }

    searchParams = map
      ? {
          ...searchParams,
          location: {
            bounds: search.location.bounds,
          },
        }
      : {
          ...searchParams,
          location: {
            lat: search.location.lat,
            lng: search.location.lng,
            range: search.location.range,
          },
          sort: search.sort,
        }

    return await axios
      .post(
        `${API_URL}/api/jobs/search` +
          (!map ? `?type=jobs&page=${currentPage}` : "?type=map"),
        {
          search: searchParams,
        },
        {
          cancelToken,
        },
      )
      .catch(function (err) {})
  },
)

export const getActiveFilterCount = createSelector(
  [(state) => state.search.activeFilters],
  (activeFilters) => {
    return Object.values(activeFilters).reduce(
      (acc, cur) => acc + (Array.isArray(cur) ? cur.length : 1),
      0,
    )
  },
)

export const getJobCount = createSelector(
  [(state) => state.search.meta],
  (meta) => {
    return meta?.total || 0
  },
)

// EXPORTS
export const {
  setTerm,
  setLocation,
  setJobCount,
  setResults,
  setMeta,
  setFilters,
  setActiveFilters,
  setSort,
  setMapSelectedJob,
} = searchSlice.actions

export const searchReducer = searchSlice.reducer
