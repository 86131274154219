import "@fortawesome/fontawesome-svg-core/styles.css"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fat } from "@fortawesome/pro-thin-svg-icons"
// import { fas } from "@fortawesome/pro-solid-svg-icons"
// import { far } from "@fortawesome/pro-regular-svg-icons"
// import { fal } from "@fortawesome/pro-light-svg-icons"
// import { fad } from "@fortawesome/pro-duotone-svg-icons"
// import { fass } from "@fortawesome/sharp-solid-svg-icons"
// import { fasr } from "@fortawesome/sharp-regular-svg-icons"
// import { fasl } from "@fortawesome/sharp-light-svg-icons"
// import { fast } from "@fortawesome/sharp-thin-svg-icons"

// library.add(fas, far, fal, fat, fad, fass, fasr, fasl, fast)
library.add(fat)
