import { createSlice } from "@reduxjs/toolkit"

export const trackingSlice = createSlice({
  name: "tracking",
  initialState: {
    source: null,
    target: null,
  },
  reducers: {
    setSource: (state, action) => {
      state.source = action.payload.source
      state.target = action.payload.target
    },
  },
})

// EXPORTS
export const { setSource } = trackingSlice.actions

export const trackingReducer = trackingSlice.reducer
