import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { useDispatch, useSelector } from "react-redux"
import { persistReducer } from "redux-persist"
import { bookmarksReducer } from "./slices/bookmarksSlice"
import storage from "./customStorage"
import { searchReducer } from "./slices/searchSlice"
import { pageReducer } from "./slices/pageSlice"
import { trackingReducer } from "./slices/trackingSlice"

const bookmarksPersistConfig = {
  key: "bookmarks",
  storage: storage,
  whitelist: ["bookmarks"],
}

const rootReducer = combineReducers({
  bookmarks: persistReducer(bookmarksPersistConfig, bookmarksReducer),
  search: searchReducer,
  page: pageReducer,
  tracking: trackingReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false })
  },
})

export const useAppDispatch = () => useDispatch()
export const useAppSelector = useSelector
