import { createSlice } from "@reduxjs/toolkit"

export const pageSlice = createSlice({
  name: "page",
  initialState: {
    currentPageBreadcrumbs: [],
    headerVisible: true,
  },
  reducers: {
    setCurrentPageBreadcrumbs: (state, action) => {
      state.currentPageBreadcrumbs = action.payload
    },
    setHeaderVisible: (state, action) => {
      state.headerVisible = action.payload
    },
  },
})

export const { setCurrentPageBreadcrumbs, setHeaderVisible } = pageSlice.actions

export const pageReducer = pageSlice.reducer
